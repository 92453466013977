// src/components/ProfilePage.js
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import supabase from '../supabaseClient';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ProfilePage() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        setUsername(user.user_metadata.username || '');
        setEmail(user.email);
      }
    } catch (error) {
      console.error('Error al obtener el perfil del usuario:', error);
    }
  };

  const handleUpdateUsername = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const { data, error } = await supabase.auth.updateUser({
        data: { username },
      });

      if (error) {
        toast.error('Error al actualizar el nombre de usuario');
        console.error('Error al actualizar el nombre de usuario:', error);
      } else {
        toast.success('Nombre de usuario actualizado correctamente');
      }
    } catch (error) {
      toast.error('Error al actualizar el nombre de usuario');
      console.error('Error al actualizar el nombre de usuario:', error);
    }
  };

  const handleUpdateEmail = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const { data, error } = await supabase.auth.updateUser({
        email,
      });

      if (error) {
        let errorMessage = 'Error al actualizar el correo electrónico';

        switch (error.message) {
          case 'Unable to validate email address: invalid format':
            errorMessage = 'El formato del correo electrónico no es válido';
            break;
          case 'Unable to update user: email already in use':
            errorMessage = 'El correo electrónico ya está en uso por otro usuario';
            break;
          default:
            errorMessage = 'Error al actualizar el correo electrónico';
        }

        toast.error(errorMessage);
        console.error('Error al actualizar el correo electrónico:', error);
      } else {
        toast.success('Correo electrónico actualizado correctamente');
      }
    } catch (error) {
      toast.error('Error al actualizar el correo electrónico');
      console.error('Error al actualizar el correo electrónico:', error);
    }
  };

  const handleUpdatePassword = async () => {
    if (newPassword !== confirmPassword) {
      toast.error('Las contraseñas no coinciden');
      return;
    }

    try {
      const { data: { user } } = await supabase.auth.getUser();
      const { data, error } = await supabase.auth.updateUser({
        password: newPassword,
      });

      if (error) {
        let errorMessage = 'Error al actualizar la contraseña';

        switch (error.message) {
          case 'New password should be different from the old password.':
            errorMessage = 'La nueva contraseña debe ser diferente a la contraseña actual';
            break;
          case 'Password should be at least 6 characters':
            errorMessage = 'La contraseña debe tener al menos 6 caracteres';
            break;
          default:
            errorMessage = 'Error al actualizar la contraseña';
        }

        toast.error(errorMessage);
        console.error('Error al actualizar la contraseña:', error);
      } else {
        toast.success('Contraseña actualizada correctamente');
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
      }
    } catch (error) {
      toast.error('Error al actualizar la contraseña');
      console.error('Error al actualizar la contraseña:', error);
    }
  };

  return (
    <motion.div
      className="min-h-screen bg-gradient-to-r from-blue-100 to-purple-200 flex justify-center items-center py-12 px-4 sm:px-6 lg:px-8"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="max-w-md w-full bg-white rounded-2xl shadow-md p-8">
        <h2 className="text-3xl font-bold mb-6">Perfil de usuario</h2>
        <div className="mb-6">
          <label htmlFor="username" className="block font-semibold mb-2">
            <FontAwesomeIcon icon={faUser} className="mr-2" />
            Nombre de usuario
          </label>
          <div className="flex">
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-l-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
              required
            />
            <button
              type="button"
              className="px-4 py-2 bg-blue-600 text-white rounded-r-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-300"
              onClick={handleUpdateUsername}
            >
              Actualizar
            </button>
          </div>
        </div>
        <div className="mb-6">
          <label htmlFor="email" className="block font-semibold mb-2">
            <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
            Correo electrónico
          </label>
          <div className="flex">
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-l-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
              required
            />
            <button
              type="button"
              className="px-4 py-2 bg-blue-600 text-white rounded-r-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-300"
              onClick={handleUpdateEmail}
            >
              Actualizar
            </button>
          </div>
        </div>
        <div className="mb-6">
          <label htmlFor="currentPassword" className="block font-semibold mb-2">
            <FontAwesomeIcon icon={faLock} className="mr-2" />
            Contraseña actual
          </label>
          <input
            type="password"
            id="currentPassword"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
        </div>
        <div className="mb-6">
          <label htmlFor="newPassword" className="block font-semibold mb-2">
            <FontAwesomeIcon icon={faLock} className="mr-2" />
            Nueva contraseña
          </label>
          <input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
        </div>
        <div className="mb-6">
          <label htmlFor="confirmPassword" className="block font-semibold mb-2">
            <FontAwesomeIcon icon={faLock} className="mr-2" />
            Confirmar nueva contraseña
          </label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
        </div>
        <div className="flex justify-end">
          <button
            type="button"
            className="px-6 py-2 bg-blue-600 text-white rounded-full shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-300"
            onClick={handleUpdatePassword}
          >
            Actualizar contraseña
          </button>
        </div>
      </div>
      <ToastContainer />
    </motion.div>
  );
}

export default ProfilePage;