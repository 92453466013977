// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import supabase from './supabaseClient';
import LandingPage from './components/LandingPage';
import PredictionForm from './components/PredictionForm';
import ResultPage from './components/ResultPage';
import DailyPredictions from './components/DailyPredictions';
import ChatbotPage from './components/ChatbotPage';
import BettingDistributionPage from './components/BettingDistributionPage';
import ProfilePage from './components/ProfilePage';
import HomePage from './components/HomePage';
import Sidebar from './components/Sidebar';
import './App.css';

function App() {
  const [session, setSession] = useState(null);

  useEffect(() => {
    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, []);

  return (
    <Router>
      {session ? (
        <Sidebar>
          <Routes>
            <Route
              path="/home"
              element={
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <HomePage />
                </motion.div>
              }
            />
            <Route
              path="/"
              element={<Navigate to="/home" replace />}
            />
            <Route
              path="/prediction"
              element={
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  className="min-h-screen bg-gradient-to-r from-blue-400 to-purple-500"
                >
                  <PredictionForm />
                </motion.div>
              }
            />
            <Route
              path="/result"
              element={
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <ResultPage />
                </motion.div>
              }
            />
            <Route
              path="/daily-predictions"
              element={
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <DailyPredictions />
                </motion.div>
              }
            />
            <Route
              path="/chatbot"
              element={
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <ChatbotPage />
                </motion.div>
              }
            />
            <Route
              path="/betting-distribution"
              element={
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <BettingDistributionPage />
                </motion.div>
              }
            />
            <Route
              path="/profile"
              element={
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <ProfilePage />
                </motion.div>
              }
            />
          </Routes>
        </Sidebar>
      ) : (
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      )}
    </Router>
  );
}

export default App;