import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTrophy, faGift, faArrowLeft, faExclamationCircle, faCalendarAlt, faFutbol } from '@fortawesome/free-solid-svg-icons';

function ResultPage() {
  const location = useLocation();
  const { prediction, event } = location.state;
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <motion.div
    className="min-h-screen bg-gradient-to-r from-blue-100 to-purple-200 py-8 px-4 sm:px-6 lg:px-8"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="max-w-7xl mx-auto">
        <div className="bg-white rounded-lg shadow-xl overflow-hidden">
          <div className="bg-gradient-to-r from-blue-400 to-purple-500 p-4 flex items-center justify-between">
            <div className="flex items-center">
              <FontAwesomeIcon icon={faFutbol} className="text-white text-2xl mr-2" />
              <h2 className="text-2xl font-bold text-white">{event}</h2>
            </div>
            <motion.button
              className="py-2 px-4 bg-white text-blue-600 font-bold rounded-lg shadow-md hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white transition duration-300"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleGoBack}
            >
              <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
              Volver
            </motion.button>
          </div>
          <div className="p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <motion.div
                className="bg-gradient-to-r from-blue-50 to-purple-50 rounded-lg border border-blue-200 p-6 shadow-md transition duration-300 hover:shadow-lg"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="flex items-center justify-between mb-4">
                  <h2 className="text-xl font-bold text-blue-600">
                    <FontAwesomeIcon icon={faTrophy} className="mr-2" />
                    Predicción principal
                  </h2>
                </div>
                <p className="text-lg mb-2">
                  <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2" />
                  {prediction.main_prediction.prediction}
                </p>
                <p className="text-gray-600">{prediction.main_prediction.explanation}</p>
              </motion.div>
              <motion.div
                className="bg-gradient-to-r from-blue-100 to-purple-100 rounded-lg border border-blue-200 p-6 shadow-md transition duration-300 hover:shadow-lg"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <div className="flex items-center justify-between mb-4">
                  <h2 className="text-xl font-bold text-blue-600">
                    <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
                    Predicción alternativa
                  </h2>
                </div>
                <p className="text-lg mb-2">
                  <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2" />
                  {prediction.apuestasdeportivas_prediction.prediction}
                </p>
                <p className="text-gray-600">{prediction.apuestasdeportivas_prediction.explanation}</p>
              </motion.div>
            </div>
            {prediction.other_predictions.length > 0 && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
              >
                <h2 className="text-xl font-bold mt-8 mb-4 flex items-center text-blue-600">
                  <FontAwesomeIcon icon={faGift} className="mr-2" />
                  Otras predicciones
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {prediction.other_predictions.map((otherPrediction, index) => (
                    <motion.div
                      key={index}
                      className="bg-gradient-to-r from-purple-100 to-pink-100 rounded-lg border border-purple-200 p-6 shadow-md transition duration-300 hover:shadow-lg"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5, delay: 0.4 + index * 0.1 }}
                    >
                      <p className="text-lg font-bold mb-2">{otherPrediction.title}</p>
                      <p className="text-gray-600">{otherPrediction.explanation}</p>
                    </motion.div>
                  ))}
                </div>
              </motion.div>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default ResultPage;