// src/components/HomePage.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faSearch, faChartPie } from '@fortawesome/free-solid-svg-icons';
import supabase from '../supabaseClient';

function HomePage() {
  const [username, setUsername] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        setUsername(user.user_metadata.username);
      }
    };

    fetchUser();
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.8,
        delay: 0.2,
        staggerChildren: 0.2,
        delayChildren: 0.4,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6, ease: 'easeOut' },
    },
  };

  const dailyPredictionsVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
        delay: 0.6,
      },
    },
    hover: {
      scale: 1.05,
      boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
      transition: {
        duration: 0.3,
        ease: 'easeOut',
      },
    },
  };

  const featureVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: (index) => ({
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.6,
        ease: 'easeOut',
        delay: 0.8 + index * 0.2,
      },
    }),
  };

  return (
    <motion.div
      className="min-h-screen bg-gradient-to-r from-blue-50 to-purple-100 flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      {/* Título de bienvenida */}
      <motion.div
        className="text-center mb-12"
        variants={itemVariants}
      >
        <motion.h1
          className="text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-600 mb-2"
          initial={{ scale: 0.5 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.8, ease: 'easeOut' }}
        >
          ¡Bienvenido, {username}!
        </motion.h1>
        <motion.p
          className="text-xl text-gray-600"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, ease: 'easeOut', delay: 0.2 }}
        >
          Descubre el poder de las apuestas inteligentes con BetWise
        </motion.p>
      </motion.div>
  
      {/* Sección de predicciones del día */}
      <motion.section
        className="bg-white rounded-2xl shadow-lg p-8 mb-8 w-full max-w-2xl text-center cursor-pointer"
        variants={dailyPredictionsVariants}
        whileHover="hover"
        onClick={() => navigate('/daily-predictions')}
      >
        <motion.div
          initial={{ rotate: -180, scale: 0.5 }}
          animate={{ rotate: 0, scale: 1 }}
          transition={{ duration: 0.8, ease: 'backOut' }}
        >
          <FontAwesomeIcon icon={faCalendarAlt} className="text-4xl text-green-500 mb-4" />
        </motion.div>
        <motion.h2
          className="text-2xl font-bold text-gray-800 mb-2"
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.6, ease: 'easeOut', delay: 0.2 }}
        >
          Predicciones del día
        </motion.h2>
        <motion.p
          className="text-gray-600 mb-6"
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.6, ease: 'easeOut', delay: 0.4 }}
        >
          Obtén predicciones diarias generadas automáticamente
        </motion.p>
        <motion.button
          className="px-6 py-3 bg-green-500 text-white rounded-full shadow-md hover:bg-green-600 transition duration-200 ease-in-out text-lg font-bold"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.6, ease: 'easeOut', delay: 0.6 }}
        >
          Ver predicciones
        </motion.button>
      </motion.section>
  
      {/* Sección de características */}
      <motion.section
        className="grid grid-cols-1 sm:grid-cols-2 gap-8 max-w-4xl w-full"
        variants={containerVariants}
      >
        {/* Explorador de eventos */}
        <motion.div
          className="bg-white rounded-2xl shadow-lg p-8 flex flex-col items-center justify-center text-center cursor-pointer"
          variants={featureVariants}
          custom={0}
          whileHover={{ scale: 1.05, boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)' }}
          transition={{ duration: 0.3, ease: 'easeOut' }}
          onClick={() => navigate('/prediction')}
        >
          <motion.div
            initial={{ rotate: -90, scale: 0.5 }}
            animate={{ rotate: 0, scale: 1 }}
            transition={{ duration: 0.8, ease: 'backOut' }}
          >
            <FontAwesomeIcon icon={faSearch} className="text-4xl text-blue-500 mb-4" />
          </motion.div>
          <motion.h2
            className="text-2xl font-bold text-gray-800 mb-2"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.6, ease: 'easeOut', delay: 0.2 }}
          >
            Explorador de eventos
          </motion.h2>
          <motion.p
            className="text-gray-600 mb-6"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.6, ease: 'easeOut', delay: 0.4 }}
          >
            Busca y obtén predicciones para eventos deportivos
          </motion.p>
          <motion.button
            className="px-6 py-3 bg-blue-500 text-white rounded-full shadow-md hover:bg-blue-600 transition duration-200 ease-in-out"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.6, ease: 'easeOut', delay: 0.6 }}
          >
            Explorar eventos
          </motion.button>
        </motion.div>
  
        {/* Estratega de apuestas */}
        <motion.div
          className="bg-white rounded-2xl shadow-lg p-8 flex flex-col items-center justify-center text-center cursor-pointer"
          variants={featureVariants}
          custom={1}
          whileHover={{ scale: 1.05, boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)' }}
          transition={{ duration: 0.3, ease: 'easeOut' }}
          onClick={() => navigate('/betting-distribution')}
        >
          <motion.div
            initial={{ rotate: 90, scale: 0.5 }}
            animate={{ rotate: 0, scale: 1 }}
            transition={{ duration: 0.8, ease: 'backOut' }}
          >
            <FontAwesomeIcon icon={faChartPie} className="text-4xl text-yellow-500 mb-4" />
          </motion.div>
          <motion.h2
            className="text-2xl font-bold text-gray-800 mb-2"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.6, ease: 'easeOut', delay: 0.2 }}
          >
            Estratega de apuestas
          </motion.h2>
          <motion.p
            className="text-gray-600 mb-6"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.6, ease: 'easeOut', delay: 0.4 }}
          >
            Optimiza la distribución de tus apuestas
          </motion.p>
          <motion.button
            className="px-6 py-3 bg-yellow-500 text-white rounded-full shadow-md hover:bg-yellow-600 transition duration-200 ease-in-out"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.6, ease: 'easeOut', delay: 0.6 }}
          >
            Optimizar apuestas
          </motion.button>
        </motion.div>
      </motion.section>
  
      {/* Mensaje de pie de página */}
      <motion.div
        className="mt-12 text-center"
        variants={itemVariants}
      >
        <p className="text-lg text-gray-600">
          Impulsado por{' '}
          <motion.span
            className="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-600"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.6, ease: 'easeOut', delay: 0.8 }}
          >
            BETTIA v2.1 Beta™
          </motion.span>
          , nuestro potente modelo de predicción
        </p>
      </motion.div>
    </motion.div>
  );
}

export default HomePage;