import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faSpinner, faCheckCircle, faPlay, faInfoCircle, faMoneyBillWave, faPercentage, faComment, faChartLine, faTrophy } from '@fortawesome/free-solid-svg-icons';
import supabase from '../supabaseClient';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const API_KEY = 'sk-proj-b1bKkCQOIWONmF6iCt9bT3BlbkFJRGPdy4rXGsF4bzwSb5ba';

function BettingDistributionPage() {
  const [amount, setAmount] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [distribution, setDistribution] = useState(null);

  const handleDistribution = async () => {
    if (!amount) {
      toast.error('Por favor, ingresa una cantidad antes de continuar.');
      return;
    }

    setIsLoading(true);

    try {
      const currentDate = new Date().toISOString().split('T')[0];
      const { data, error } = await supabase
        .from('daily_predictions')
        .select('*')
        .like('date', `${currentDate}%`);

      if (error) {
        console.error('Error al obtener las predicciones del día:', error);
        toast.error('Ha ocurrido un error al obtener las predicciones del día. Por favor, inténtalo de nuevo.');
      } else {
        console.log('Predicciones del día obtenidas:', data);
        const formattedPredictions = data.map((prediction) => {
          return `Evento: ${prediction.event || 'N/A'}
Competición: ${prediction.competition || 'N/A'}
Fecha: ${prediction.date || 'N/A'}
Predicción: ${prediction.prediction || 'N/A'}
Cuota: ${prediction.odds || 'N/A'}
Análisis: ${prediction.analysis || 'N/A'}`;
        }).join('\n\n');

        await distributeBets(formattedPredictions);
      }
    } catch (error) {
      console.error('Error al obtener las predicciones del día:', error);
      toast.error('Ha ocurrido un error al obtener las predicciones del día. Por favor, inténtalo de nuevo.');
    } finally {
      setIsLoading(false);
    }
  };

  const distributeBets = async (predictions) => {
    try {
      const numPredictions = predictions.split('\n\n').length;
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${API_KEY}`,
        },
        body: JSON.stringify({
          model: 'gpt-3.5-turbo-0125',
          messages: [
            {
              role: 'system',
              content: 'Eres un asistente experto en apuestas deportivas.',
            },
            {
              role: 'user',
              content: `Tengo un presupuesto de ${amount} para invertir en apuestas. Aquí te proporciono las ${numPredictions} predicciones del día:
      
      ${predictions}
      
      Por favor, distribuye mi presupuesto entre las ${numPredictions} apuestas que te he enviado, teniendo en cuenta las cuotas y el análisis proporcionado. Analiza detalladamente la explicación para determinar la confianza en cada predicción y asignar más o menos dinero en función de ello. El objetivo es maximizar las ganancias potenciales del usuario, pero al mismo tiempo, distribuir el presupuesto de manera equilibrada, ya que siempre habrá apuestas perdedoras.
      
      Considera los siguientes puntos al realizar la distribución:
      - Asigna más dinero a las apuestas con explicaciones sólidas y convincentes, y menos dinero a aquellas con explicaciones menos fundamentadas.
      - Diversifica el presupuesto entre todas las apuestas para minimizar el riesgo de perder todo el dinero en una sola apuesta.
      - Tiene en cuenta las cuotas para calcular el potencial de ganancias de cada apuesta y ajusta la distribución en consecuencia.
      
      Devuelve la distribución en formato JSON con la siguiente estructura:
      
      {
        "distributions": [
          {
            "event": "Nombre del evento",
            "prediction": "Predicción",
            "amount": "Cantidad a apostar",
            "odds": "Cuota",
            "potential_profit": "Ganancia potencial",
            "confidence": "Nivel de confianza (alto, medio, bajo)",
            "explanation": "Explica detalladamente por qué has asignado esa cantidad de dinero a esta predicción, basándote en el análisis proporcionado y tu evaluación de la confianza en la predicción."
          },
          ...
        ],
        "total_amount": "Cantidad total apostada",
        "remaining_amount": "Cantidad restante del presupuesto",
        "summary": "Proporciona un resumen de la estrategia de distribución, explicando cómo has equilibrado el presupuesto entre las apuestas y los factores que has considerado para maximizar las ganancias potenciales."
      }
      
      IMPORTANTE: Devuelve SIEMPRE ${numPredictions} predicciones, basándote en las apuestas y pronósticos que te he enviado. No inventes nuevas predicciones.
      `,
            },
          ],
          temperature: 0.7,
          response_format: {
            type: 'json_object',
          },
        }),
      });

      if (!response.ok) {
        throw new Error('Oops! Something went wrong while processing your request.');
      }

      const responseData = await response.json();
      console.log('Respuesta de la API de OpenAI:', responseData);
      const distribution = JSON.parse(responseData.choices[0].message.content.trim());
      setDistribution(distribution);
    } catch (error) {
      console.error('Error while fetching distribution data:', error);
      toast.error('Ha ocurrido un error al obtener la distribución de apuestas. Por favor, inténtalo de nuevo.');
    }
  };

  return (
    <motion.div
      className="min-h-screen bg-gradient-to-r from-blue-100 to-purple-100 py-8 px-4 sm:px-6 lg:px-8"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="max-w-4xl mx-auto">
        <div className="bg-white rounded-lg shadow-xl overflow-hidden">
          {!distribution ? (
            <>
              <motion.div
                className="bg-gradient-to-r from-blue-500 to-purple-500 p-6"
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <h2 className="text-3xl font-bold text-white flex items-center">
                  <FontAwesomeIcon icon={faCoins} className="mr-2" />
                  Distribución de apuestas
                </h2>
                <p className="text-white text-lg mt-2">
                  {new Date().toLocaleDateString('es-ES', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </p>
              </motion.div>
              <div className="p-6">
                <div className="mb-6">
                  <p className="text-lg text-gray-700">
                    <FontAwesomeIcon icon={faTrophy} className="text-blue-500 mr-2" />
                    Analizamos tus predicciones con Inteligencia Artificial para optimizar la distribución de tus apuestas.
                  </p>
                  <p className="text-lg text-gray-700 mt-2">
                    <FontAwesomeIcon icon={faChartLine} className="text-green-500 mr-2" />
                    Nuestro sistema experto te ayuda a maximizar tus ganancias potenciales.
                  </p>
                  <p className="text-lg text-gray-700 mt-2">
                    <FontAwesomeIcon icon={faInfoCircle} className="text-yellow-500 mr-2" />
                    Ingresa el monto que deseas apostar y genera la distribución óptima para tus apuestas.
                  </p>
                </div>
                <div>
                  <label htmlFor="amount" className="block text-lg font-medium text-gray-700 mb-2">
                    Ingresa la cantidad que deseas apostar:
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <FontAwesomeIcon icon={faMoneyBillWave} className="text-gray-400" />
                    </div>
                    <input
                      type="number"
                      name="amount"
                      id="amount"
                      className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 pr-12 py-2 text-base border-gray-300 rounded-md"
                      placeholder="Ingresa la cantidad"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      min="0"
                      step="0.01"
                    />
                  </div>
                </div>
                <div className="mt-6">
                  <button
                    type="button"
                    className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition duration-300 ease-in-out transform hover:scale-105"
                    onClick={handleDistribution}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <div className="flex items-center">
                        <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
                        <span>Generando distribución...</span>
                      </div>
                    ) : (
                      <div className="flex items-center">
                        <FontAwesomeIcon icon={faPlay} className="mr-2" />
                        <span>Generar distribución</span>
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </>
        ) : (
          <>
            <motion.div
              className="bg-gradient-to-r from-blue-500 to-purple-500 p-4 flex items-center justify-between"
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <div>
                <h2 className="text-2xl font-bold text-white flex items-center">
                  <FontAwesomeIcon icon={faCheckCircle} className="text-green-500 mr-2" />
                  Distribución generada
                </h2>
                <p className="text-white text-lg mt-2">
                  {new Date().toLocaleDateString('es-ES', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </p>
              </div>
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-300 ease-in-out transform hover:scale-105"
                onClick={() => {
                  setDistribution(null);
                  setAmount('');
                }}
              >
                Volver a generar
              </button>
            </motion.div>
            <div className="p-6">
              <motion.div
                className="grid grid-cols-1 md:grid-cols-2 gap-6"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                {distribution.distributions.map((item, index) => (
                  <motion.div
                    key={index}
                    className="bg-gradient-to-r from-blue-100 to-purple-100 rounded-lg shadow-md overflow-hidden transition duration-300 ease-in-out transform hover:scale-105"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                  >
                    <div className="bg-gradient-to-r from-blue-500 to-purple-500 p-4 flex justify-between items-center">
                      <h4 className="text-xl font-bold text-white">{item.event}</h4>
                      <div className="flex items-center">
                        <FontAwesomeIcon icon={faChartLine} className="text-white mr-2" />
                        <p className="text-base font-semibold text-white">Cuota: {item.odds}</p>
                      </div>
                    </div>
                    <div className="p-6">
                      <div className="mb-4">
                        <div className="flex items-center">
                          <FontAwesomeIcon icon={faInfoCircle} className="text-blue-500 mr-2" />
                          <p className="text-base font-semibold">Predicción: {item.prediction}</p>
                        </div>
                      </div>
                      <div className="bg-white rounded-lg shadow-md p-4 mb-4">
                        <div className="flex justify-between items-center mb-2">
                          <div className="flex items-center">
                            <FontAwesomeIcon icon={faMoneyBillWave} className="text-green-500 mr-2" />
                            <p className="text-base font-semibold">Cantidad a apostar:</p>
                          </div>
                          <p className="text-base font-bold">${item.amount}</p>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex items-center">
                            <FontAwesomeIcon icon={faTrophy} className="text-yellow-500 mr-2" />
                            <p className="text-base font-semibold">Ganancia potencial:</p>
                          </div>
                          <p className="text-base font-bold">${item.potential_profit}</p>
                        </div>
                      </div>
                      <div className="mb-4">
                        <div className="flex items-center">
                          <FontAwesomeIcon icon={faPercentage} className="text-blue-500 mr-2" />
                          <p className="text-base font-semibold">Nivel de confianza:</p>
                        </div>
                        <div
                          className={`mt-2 rounded-full py-1 px-4 text-white font-semibold text-sm ${
                            item.confidence === 'alto'
                              ? 'bg-green-500'
                              : item.confidence === 'medio'
                              ? 'bg-yellow-500'
                              : 'bg-red-500'
                          }`}
                        >
                          {item.confidence}
                        </div>
                      </div>
                      <div>
                        <div className="flex items-start">
                          <FontAwesomeIcon icon={faComment} className="text-blue-500 mr-2 mt-1" />
                          <p className="text-sm">
                            <span className="font-semibold">Explicación:</span> {item.explanation}
                          </p>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </motion.div>
              <div className="mt-8">
                <div className="bg-gradient-to-r from-green-500 to-green-600 rounded-lg shadow-lg p-6">
                  <div className="flex justify-between items-center mb-4">
                    <div className="flex items-center">
                      <FontAwesomeIcon icon={faTrophy} className="text-white text-2xl mr-2" />
                      <p className="text-xl font-bold text-white">Ganancia potencial total:</p>
                    </div>
                    <p className="text-2xl font-bold text-white">
                      ${distribution.distributions.reduce((total, item) => total + parseFloat(item.potential_profit), 0).toFixed(2)}
                    </p>
                  </div>
                  <div className="flex justify-between items-center">
                    <div className="flex items-center">
                      <FontAwesomeIcon icon={faMoneyBillWave} className="text-white text-2xl mr-2" />
                      <p className="text-xl font-bold text-white">Cantidad total apostada:</p>
                    </div>
                    <p className="text-2xl font-bold text-white">${distribution.total_amount}</p>
                  </div>
                </div>
                <div className="mt-6 bg-white rounded-lg shadow-lg p-6">
                  <h3 className="text-xl font-bold mb-4">Resumen de la estrategia de distribución:</h3>
                  <p className="text-gray-700">{distribution.summary}</p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
    <ToastContainer />
  </motion.div>
);
}

export default BettingDistributionPage;