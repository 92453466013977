import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFutbol, faSignInAlt, faUserPlus, faChartLine, faBrain, faTrophy, faUser, faEnvelope, faLock, faTimes } from '@fortawesome/free-solid-svg-icons';
import supabase from '../supabaseClient';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function LandingPage() {
  const [showForm, setShowForm] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const storedEmail = localStorage.getItem('rememberedEmail');
    if (storedEmail) {
      setEmail(storedEmail);
      setRememberMe(true);
    }
  }, []);

  const handleRememberMe = (e) => {
    setRememberMe(e.target.checked);
    if (e.target.checked) {
      localStorage.setItem('rememberedEmail', email);
    } else {
      localStorage.removeItem('rememberedEmail');
    }
  };

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, staggerChildren: 0.2 },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 },
    },
  };

  const featureVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, staggerChildren: 0.2, delayChildren: 0.2 },
    },
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const { user, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) throw error;

      toast.success('Inicio de sesión exitoso');
      navigate('/home');
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    try {
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            username,
          },
        },
      });

      if (error) throw error;

      toast.success('Registro exitoso. Por favor, revisa tu correo electrónico para confirmar tu cuenta.');
      navigate('/');
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <motion.div
      className="min-h-screen bg-gradient-to-r from-blue-100 to-purple-200 flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.header
        className="flex items-center justify-between w-full max-w-7xl mb-12"
        variants={itemVariants}
      >
        <motion.div className="flex items-center">
          <motion.h1 className="text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-purple-600 mr-2">
            BetWise
          </motion.h1>
          <span className="bg-blue-500 text-white text-xs font-semibold px-2 py-1 rounded-full uppercase tracking-wide">Beta</span>
        </motion.div>
      </motion.header>
      <motion.main
        className="w-full max-w-7xl flex flex-col lg:flex-row items-center justify-between"
        variants={itemVariants}
      >
        <motion.div className="lg:w-1/2 mb-12 lg:mb-0 lg:pr-12">
          <motion.h2
            className="text-5xl sm:text-6xl font-extrabold mb-6 text-blue-600 leading-tight"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Predicciones deportivas precisas con IA avanzada
          </motion.h2>
          <motion.p
            className="text-xl text-gray-600 mb-8"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            Obtén predicciones ganadoras y mejora tus probabilidades de éxito en las apuestas deportivas con la tecnología de inteligencia artificial más avanzada.
          </motion.p>
          <motion.div className="flex space-x-4">
            <motion.button
              className="px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-600 text-white rounded-full shadow-md hover:from-blue-600 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-300"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
              onClick={() => setShowForm('signup')}
            >
              Comenzar ahora
            </motion.button>
            <motion.button
              className="px-6 py-3 bg-blue-600 text-white rounded-full shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-300"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.6 }}
              onClick={() => setShowForm('login')}
            >
              <FontAwesomeIcon icon={faSignInAlt} className="mr-2" />
              Iniciar sesión
            </motion.button>
          </motion.div>
        </motion.div>
        <motion.div
          className="lg:w-1/2 grid grid-cols-1 sm:grid-cols-2 gap-8"
          variants={featureVariants}
          initial="hidden"
          animate="visible"
        >
          <motion.div
            className="bg-white rounded-2xl shadow-md p-6 flex flex-col items-center text-center"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            variants={itemVariants}
          >
            <FontAwesomeIcon icon={faFutbol} className="text-4xl text-blue-600 mb-4" />
            <h3 className="text-xl font-bold mb-2">Múltiples deportes</h3>
            <p className="text-gray-600">Predicciones para fútbol, baloncesto, tenis y más.</p>
          </motion.div>
          <motion.div
            className="bg-white rounded-2xl shadow-md p-6 flex flex-col items-center text-center"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            variants={itemVariants}
          >
            <FontAwesomeIcon icon={faBrain} className="text-4xl text-purple-600 mb-4" />
            <h3 className="text-xl font-bold mb-2">IA avanzada</h3>
            <p className="text-gray-600">Algoritmos de aprendizaje automático líderes en la industria.</p>
          </motion.div>
          <motion.div
            className="bg-white rounded-2xl shadow-md p-6 flex flex-col items-center text-center"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            variants={itemVariants}
          >
            <FontAwesomeIcon icon={faChartLine} className="text-4xl text-green-600 mb-4" />
            <h3 className="text-xl font-bold mb-2">Análisis de datos</h3>
            <p className="text-gray-600">Utilizamos grandes cantidades de datos para generar predicciones precisas.</p>
          </motion.div>
          <motion.div
            className="bg-white rounded-2xl shadow-md p-6 flex flex-col items-center text-center"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            variants={itemVariants}
          >
            <FontAwesomeIcon icon={faTrophy} className="text-4xl text-yellow-500 mb-4" />
            <h3 className="text-xl font-bold mb-2">Resultados comprobados</h3>
            <p className="text-gray-600">Nuestros usuarios han mejorado significativamente sus ganancias.</p>
          </motion.div>
        </motion.div>
      </motion.main>
      <AnimatePresence>
        {showForm && (
          <motion.div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            onClick={() => setShowForm(null)}
          >
            <motion.div
              className="bg-white rounded-2xl shadow-md p-8 max-w-md w-full"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              transition={{ duration: 0.3 }}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex justify-end">
                <button
                  className="text-gray-500 hover:text-gray-700 focus:outline-none"
                  onClick={() => setShowForm(null)}
                >
                  <FontAwesomeIcon icon={faTimes} className="text-2xl" />
                </button>
              </div>
              {showForm === 'login' ? (
                <>
                  <h2 className="text-3xl font-bold mb-6">Iniciar sesión</h2>
                  <form onSubmit={handleLogin}>
                    {/* Campos del formulario de inicio de sesión */}
                    <div className="mb-4">
                      <label htmlFor="email" className="block font-semibold mb-2">
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                        required
                      />
                    </div>
                    <div className="mb-6">
                      <label htmlFor="password" className="block font-semibold mb-2">
                        Contraseña
                      </label>
                      <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                        required
                      />
                    </div>
                    <div className="flex items-center justify-between">
                      <label className="flex items-center">
                        <input
                          type="checkbox"
                          checked={rememberMe}
                          onChange={handleRememberMe}
                          className="form-checkbox h-5 w-5 text-blue-600 transition duration-150 ease-in-out"
                        />
                        <span className="ml-2 text-gray-600">Recordar cuenta</span>
                      </label>
                      <button
                        type="submit"
                        className="px-6 py-2 bg-blue-600 text-white rounded-full shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-300"
                      >
                        Iniciar sesión
                      </button>
                    </div>
                  </form>
                </>
              ) : (
                <>
                  <h2 className="text-3xl font-bold mb-6">Registro</h2>
                  <form onSubmit={handleSignup}>
                    {/* Campos del formulario de registro */}
                    <div className="mb-4">
                      <label htmlFor="username" className="block font-semibold mb-2">
                        Nombre de usuario
                      </label>
                      <input
                        type="text"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label htmlFor="email" className="block font-semibold mb-2">
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                        required
                      />
                    </div>
                    <div className="mb-6">
                      <label htmlFor="password" className="block font-semibold mb-2">
                        Contraseña
                      </label>
                      <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                        required
                      />
                    </div>
                    <div className="flex justify-end">
                      <button
                        type="submit"
                        className="px-6 py-2 bg-purple-600 text-white rounded-full shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-400 transition duration-300"
                      >
                        Registrarse
                      </button>
                    </div>
                  </form>
                </>
              )}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      <ToastContainer />
    </motion.div>
  );
}

export default LandingPage;