import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://zwkqsqhoxongosybcyxf.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inp3a3FzcWhveG9uZ29zeWJjeXhmIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTM3MTQ5OTksImV4cCI6MjAyOTI5MDk5OX0.WKBdl-V38O-nKy5IhNzf1NNw2tznNtvtsKv7XqpZaQw';

const supabase = createClient(supabaseUrl, supabaseAnonKey);

// supabaseClient.js
export async function resetUserPredictions() {
  const { data: lastResetData, error: lastResetError } = await supabase
    .from('last_prediction_reset')
    .select('last_reset_date')
    .single();

  if (lastResetError) {
    console.error('Error al obtener la última fecha de restablecimiento:', lastResetError);
    return;
  }

  const lastResetDate = new Date(lastResetData.last_reset_date);
  const currentDate = new Date();

  console.log('Última fecha de restablecimiento:', lastResetDate.toDateString());
  console.log('Fecha actual:', currentDate.toDateString());

  if (lastResetDate.toDateString() !== currentDate.toDateString()) {
    console.log('Las fechas son diferentes. Restableciendo las predicciones de los usuarios...');

    const { data: updateData, error: updateError } = await supabase
    .from('user_predictions')
    .update({ remaining_predictions: 10 })
    .neq('remaining_predictions', 10);

    if (updateError) {
      console.error('Error al restablecer las predicciones de los usuarios:', updateError);
    } else {
      console.log('Predicciones de los usuarios restablecidas:', updateData);

      const { data: updateResetData, error: updateResetError } = await supabase
        .from('last_prediction_reset')
        .update({ last_reset_date: currentDate.toISOString().slice(0, 10) })
        .eq('id', 1);

      if (updateResetError) {
        console.error('Error al actualizar la fecha de restablecimiento:', updateResetError);
      } else {
        console.log('Fecha de restablecimiento actualizada:', updateResetData);
      }
    }
  } else {
    console.log('Las fechas son iguales. No se restablecen las predicciones.');
  }
}

export default supabase;