import React, { useState, useEffect } from 'react';
import axios from 'axios';
import supabase from '../supabaseClient';
import { resetUserPredictions } from '../supabaseClient';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faSpinner, faFutbol, faDatabase, faCogs, faRobot, faChartBar, faSearch, faBrain, faClock, faStar, faUser, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const loadingMessages = [
  { message: "Recopilando datos", icon: faDatabase },
  { message: "Analizando estadísticas", icon: faChartBar },
  { message: "Evaluando registros", icon: faSearch },
  { message: "Simulando enfrentamientos", icon: faRobot },
  { message: "Generando predicción", icon: faCogs },
];

function PredictionForm() {
  const [event, setEvent] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [user, setUser] = useState(null);
  const [remainingPredictions, setRemainingPredictions] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        setUser(user);
        await fetchRemainingPredictions(user.id);
      }
    };
  
    fetchUser();
  
    let interval;
    if (isLoading) {
      interval = setInterval(() => {
        setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % loadingMessages.length);
      }, 2000);
    }
  
    return () => {
      clearInterval(interval);
    };
  }, [isLoading]);
  
  const fetchRemainingPredictions = async (userId) => {
    await resetUserPredictions(); // Llamada a la función para restablecer las predicciones si es un nuevo día

    const { data: userPrediction, error } = await supabase
      .from('user_predictions')
      .select('remaining_predictions')
      .eq('user_id', userId)
      .single();
  
    if (error) {
      console.error('Error al obtener las predicciones del usuario:', error);
      toast.error('Ha ocurrido un error al obtener las predicciones del usuario. Por favor, inténtalo de nuevo.');
      return;
    }
  
    if (userPrediction) {
      setRemainingPredictions(userPrediction.remaining_predictions);
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (remainingPredictions === 0) {
      toast.error('No te quedan predicciones para hoy. Intenta nuevamente mañana.');
      return;
    }

    setIsLoading(true);

    try {
      const { data: { user } } = await supabase.auth.getUser();

      if (!user) {
        console.error('El usuario no está autenticado');
        toast.error('No estás autenticado. Por favor, inicia sesión para continuar.');
        setIsLoading(false);
        return;
      }

      // Obtener las predicciones restantes del usuario antes de actualizar
      await fetchRemainingPredictions(user.id);

      const response = await axios.post('http://localhost:3000/predict', { event }, {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });

      const { data, error } = await supabase
        .from('user_predictions')
        .update({ remaining_predictions: remainingPredictions - 1 })
        .eq('user_id', user.id)
        .single();

      if (error) {
        console.error('Error al actualizar las predicciones del usuario:', error);
        toast.error('Ha ocurrido un error al actualizar las predicciones del usuario. Por favor, inténtalo de nuevo.');
        setIsLoading(false);
        return;
      }

      setRemainingPredictions(remainingPredictions - 1);
      navigate('/result', { state: { prediction: response.data.prediction, event: response.data.event } });
    } catch (error) {
      console.error('Error:', error);
      toast.error('Ha ocurrido un error al obtener la predicción. Por favor, inténtalo de nuevo.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <motion.div
      className="min-h-screen bg-gradient-to-r from-blue-100 to-purple-100 py-8 px-4 sm:px-6 lg:px-8"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="max-w-3xl mx-auto">
        <motion.div
          className="bg-white rounded-lg shadow-xl overflow-hidden"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.6, ease: "backOut" }}
        >
          <div className="bg-gradient-to-r from-blue-500 to-purple-500 p-4">
            {user && (
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div className="relative">
                    <div className="w-10 h-10 rounded-full bg-white flex items-center justify-center">
                      <FontAwesomeIcon icon={faUser} className="text-2xl text-blue-500" />
                    </div>
                    <div className="absolute bottom-0 right-0 transform translate-x-1/4 translate-y-1/4">
                      <div className="w-3 h-3 rounded-full bg-green-500 border-2 border-white"></div>
                    </div>
                  </div>
                  <div className="ml-3">
                    <h2 className="text-lg font-bold text-white">{user.user_metadata.username}</h2>
                    <p className="text-blue-200 text-sm">¡Bienvenido de vuelta!</p>
                  </div>
                </div>
                <div className="bg-white bg-opacity-20 rounded-lg py-2 px-4 flex items-center">
                  <FontAwesomeIcon icon={faStar} className="text-yellow-400 mr-2" />
                  <p className="text-sm font-bold text-white">Predicciones restantes:</p>
                  <p className="text-lg font-bold text-white ml-1">{remainingPredictions}</p>
                </div>
              </div>
            )}
          </div>
          <div className="p-8">
            <AnimatePresence>
              {!isLoading ? (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 20 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className="grid grid-cols-3 gap-8 mb-8">
                    <div className="text-center">
                      <div className="bg-blue-500 text-white rounded-full flex items-center justify-center w-16 h-16 mx-auto mb-4">
                        <span className="text-2xl font-bold">1</span>
                      </div>
                      <h3 className="text-xl font-bold mb-2 bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-500">Introduce tu evento</h3>
                      <p className="text-gray-600">Ingresa los equipos o jugadores que se enfrentarán.</p>
                    </div>
                    <div className="text-center">
                      <div className="bg-purple-500 text-white rounded-full flex items-center justify-center w-16 h-16 mx-auto mb-4">
                        <span className="text-2xl font-bold">2</span>
                      </div>
                      <h3 className="text-xl font-bold mb-2 bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-500">IA procesa los datos</h3>
                      <p className="text-gray-600">Utilizamos algoritmos avanzados y machine learning.</p>
                    </div>
                    <div className="text-center">
                      <div className="bg-green-500 text-white rounded-full flex items-center justify-center w-16 h-16 mx-auto mb-4">
                        <span className="text-2xl font-bold">3</span>
                      </div>
                      <h3 className="text-xl font-bold mb-2 bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-500">Obtén tu predicción</h3>
                      <p className="text-gray-600">Nuestro modelo genera una predicción precisa.</p>
                    </div>
                  </div>
                  <form onSubmit={handleSubmit} className="flex flex-col items-center">
                    <div className="mb-6 w-full">
                      <label htmlFor="event" className="block text-gray-700 font-bold mb-2 text-lg">
                        <FontAwesomeIcon icon={faFutbol} className="mr-2 text-blue-500" />
                        Ingresa el evento deportivo
                      </label>
                      <input
                        type="text"
                        id="event"
                        value={event}
                        onChange={(e) => setEvent(e.target.value)}
                        className="w-full px-4 py-3 text-gray-700 bg-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:bg-white text-center text-lg placeholder-gray-500 font-medium"
                        placeholder="Ej: Real Madrid vs. Barcelona"
                        required
                      />
                    </div>
                    <motion.button
                      type="submit"
                      className={`px-8 py-3 text-white rounded-lg shadow-md text-lg font-bold tracking-wide focus:outline-none focus:ring-2 focus:ring-offset-2 transition duration-300 ${
                        remainingPredictions === 0
                          ? 'bg-gray-400 cursor-not-allowed'
                          : 'bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 focus:ring-purple-500'
                      }`}
                      whileHover={remainingPredictions !== 0 ? { scale: 1.05 } : {}}
                      whileTap={remainingPredictions !== 0 ? { scale: 0.95 } : {}}
                      disabled={remainingPredictions === 0}
                    >
                      <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                      Obtener predicción
                    </motion.button>
                    {remainingPredictions === 0 && (
                      <p className="text-red-500 font-bold mt-4 text-center text-lg">
                        No te quedan predicciones para hoy. Intenta nuevamente mañana.
                      </p>
                    )}
                  </form>
                </motion.div>
              ) : (
                <motion.div
                  className="flex flex-col items-center justify-center"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 20 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className="relative mb-6">
                    <div className="w-32 h-32 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 flex items-center justify-center animate-spin">
                      <FontAwesomeIcon
                        icon={loadingMessages[currentMessageIndex].icon}
                        className="text-5xl text-white"
                      />
                    </div>
                  </div>
                  <h3 className="text-2xl font-bold mb-2 bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-500 flex items-center">
                    <FontAwesomeIcon icon={loadingMessages[currentMessageIndex].icon} className="mr-2" />
                    {loadingMessages[currentMessageIndex].message}
                  </h3>
                  <p className="text-lg text-gray-700 flex items-center font-medium">
                    <FontAwesomeIcon icon={faClock} className="mr-2 text-blue-500" />
                    Este proceso puede llevar unos segundos...
                  </p>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </motion.div>
      </div>
      <ToastContainer />
    </motion.div>
  );
}

export default PredictionForm;