import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faSpinner, faFutbol, faTrophy, faChartLine, faInfoCircle, faCheckCircle, faTimesCircle, faQuestionCircle, faRobot, faCalendarTimes, faTimes, faChevronDown, faFilter, faList, faClock } from '@fortawesome/free-solid-svg-icons';
import { format, parseISO, isSameDay } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import supabase from '../supabaseClient';

function DailyPredictions() {
  const [predictions, setPredictions] = useState([]);
  const [filteredPredictions, setFilteredPredictions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [selectedPrediction, setSelectedPrediction] = useState(null);
  const [filter, setFilter] = useState('TODAS');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentDate, setCurrentDate] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        setUser(user);
      }
    };

    fetchUser();
  }, []);

  const fetchDailyPredictions = async () => {
    try {
      const { data, error } = await supabase
        .from('daily_predictions')
        .select('*')
        .order('created_at', { ascending: true });

      if (error) {
        console.error('Error al obtener las predicciones del día:', error);
      } else {
        const selectedDateString = selectedDate.toISOString().split('T')[0];
        const selectedDatePredictions = data.filter(prediction => {
          const createdAtDate = new Date(prediction.created_at).toISOString().split('T')[0];
          return createdAtDate === selectedDateString;
        });
        const sortedPredictions = selectedDatePredictions.sort((a, b) => {
          if (a.estado && !b.estado) return 1;
          if (!a.estado && b.estado) return -1;
          return new Date(a.date) - new Date(b.date);
        });
        setPredictions(sortedPredictions);
        setFilteredPredictions(sortedPredictions);
        setCurrentDate(selectedDateString);
      }
    } catch (error) {
      console.error('Error al obtener las predicciones del día:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDailyPredictions();
  }, [selectedDate]);

  useEffect(() => {
    if (filter === 'TODAS') {
      setFilteredPredictions(predictions);
    } else if (filter === 'PENDIENTES') {
      setFilteredPredictions(predictions.filter(prediction => !prediction.estado));
    } else if (filter === 'ACERTADAS') {
      setFilteredPredictions(predictions.filter(prediction => prediction.estado === 'ACERTADO'));
    } else if (filter === 'FALLADAS') {
      setFilteredPredictions(predictions.filter(prediction => prediction.estado === 'FALLADO'));
    }
  }, [filter, predictions]);

  const handleAnalysisClick = (prediction) => {
    setSelectedPrediction(prediction);
  };

  const handleCloseAnalysis = () => {
    setSelectedPrediction(null);
  };

  const handleFilterChange = (selectedFilter) => {
    setFilter(selectedFilter);
  };

  function getEstadoText(estado) {
    switch (estado) {
      case 'ACERTADO':
        return 'Acertada';
      case 'FALLADO':
        return 'Fallada';
      case 'NULO':
        return 'Nula';
      case 'INDETERMINADO':
        return 'Indeterminado';
      default:
        return '';
    }
  }
  
  function getEstadoClasses(estado) {
    switch (estado) {
      case 'ACERTADO':
        return 'bg-green-500 text-white shadow-md border-2 border-green-600';
      case 'FALLADO':
        return 'bg-red-500 text-white shadow-md border-2 border-red-600';
      case 'NULO':
        return 'bg-yellow-500 text-white shadow-md border-2 border-yellow-600';
      case 'INDETERMINADO':
        return 'bg-gray-500 text-white shadow-md border-2 border-gray-600';
      default:
        return 'bg-blue-500 text-white shadow-md border-2 border-blue-600';
    }
  }
  
  function getEstadoBorderClasses(estado) {
    switch (estado) {
      case 'ACERTADO':
        return 'border-2 border-green-500';
      case 'FALLADO':
        return 'border-2 border-red-500';
      case 'NULO':
        return 'border-2 border-yellow-500';
      case 'INDETERMINADO':
        return 'border-2 border-gray-400';
      default:
        return 'border-2 border-gray-400';
    }
  }

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const isCurrentDate = isSameDay(selectedDate, new Date());

  return (
    <motion.div
      className="min-h-screen bg-gradient-to-r from-blue-100 to-purple-100 py-8 px-4 sm:px-6 lg:px-8 font-sans"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="max-w-7xl mx-auto">
        <div className="bg-white rounded-2xl shadow-lg p-6 mb-8">
          <div className="flex flex-col md:flex-row md:justify-between md:items-center">
            <h2 className="text-xl md:text-2xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-600 mb-4 md:mb-0">
              Predicciones de {isCurrentDate ? 'HOY' : currentDate ? format(new Date(currentDate), 'dd/MM/yyyy') : ''}
            </h2>
            <div className="flex flex-col md:flex-row md:space-x-4">
              <div className="relative mb-4 md:mb-0">
                <div className="flex items-center">
                  <FontAwesomeIcon icon={faCalendarAlt} className="text-blue-500 mr-2" />
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    dateFormat="dd/MM/yyyy"
                    className="w-40 px-4 py-2 rounded-lg text-sm font-semibold bg-gray-100 text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  />
                </div>
              </div>
              <div className="relative">
              <motion.button
  className="px-4 py-2 rounded-lg text-sm font-semibold bg-blue-500 text-white shadow-md hover:bg-blue-600 focus:outline-none flex items-center space-x-2"
  onClick={() => setIsFilterOpen(!isFilterOpen)}
  whileHover={{ scale: 1.05 }}
  whileTap={{ scale: 0.95 }}
>
  <FontAwesomeIcon icon={faFilter} />
  <span>
    {filter === 'TODAS'
      ? `Todas (${predictions.length})`
      : filter === 'PENDIENTES'
      ? `Pendientes (${predictions.filter(prediction => !prediction.estado).length})`
      : filter === 'ACERTADAS'
      ? `Acertadas (${predictions.filter(prediction => prediction.estado === 'ACERTADO').length})`
      : `Falladas (${predictions.filter(prediction => prediction.estado === 'FALLADO').length})`}
  </span>
  <FontAwesomeIcon
    icon={faChevronDown}
    className={`ml-2 transition-transform duration-300 ${isFilterOpen ? 'transform rotate-180' : ''}`}
  />
</motion.button>
                {isFilterOpen && (
                  <motion.div
                    className="absolute left-0 top-12 w-48 bg-white rounded-lg shadow-xl z-10 md:left-auto md:right-0"
                    initial={{ opacity: 0, scale: 0.8, originX: '0%', originY: 0 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.8 }}
                    transition={{ duration: 0.2 }}
                  >
                    <div className="py-2">
                      <motion.button
                        className={`block w-full text-left px-4 py-2 text-sm font-semibold hover:bg-blue-100 focus:outline-none ${
                          filter === 'TODAS' ? 'bg-blue-200' : ''
                        }`}
                        onClick={() => {
                          handleFilterChange('TODAS');
                          setIsFilterOpen(false);
                        }}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        <FontAwesomeIcon icon={faList} className="mr-2 text-blue-500" />
                        Todas ({predictions.length})
                      </motion.button>
                      <motion.button
                        className={`block w-full text-left px-4 py-2 text-sm font-semibold hover:bg-yellow-100 focus:outline-none ${
                          filter === 'PENDIENTES' ? 'bg-yellow-200' : ''
                        }`}
                        onClick={() => {
                          handleFilterChange('PENDIENTES');
                          setIsFilterOpen(false);
                        }}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        <FontAwesomeIcon icon={faClock} className="mr-2 text-yellow-500" />
                        Pendientes ({predictions.filter(prediction => !prediction.estado).length})
                      </motion.button>
                      <motion.button
                        className={`block w-full text-left px-4 py-2 text-sm font-semibold hover:bg-green-100 focus:outline-none ${
                          filter === 'ACERTADAS' ? 'bg-green-200' : ''
                        }`}
                        onClick={() => {
                          handleFilterChange('ACERTADAS');
                          setIsFilterOpen(false);
                        }}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        <FontAwesomeIcon icon={faCheckCircle} className="mr-2 text-green-500" />
                        Acertadas ({predictions.filter(prediction => prediction.estado === 'ACERTADO').length})
                      </motion.button>
                      <motion.button
                        className={`block w-full text-left px-4 py-2 text-sm font-semibold hover:bg-red-100 focus:outline-none ${
                          filter === 'FALLADAS' ? 'bg-red-200' : ''
                        }`}
                        onClick={() => {
                          handleFilterChange('FALLADAS');
                          setIsFilterOpen(false);
                        }}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} className="mr-2 text-red-500" />
                        Falladas ({predictions.filter(prediction => prediction.estado === 'FALLADO').length})
                      </motion.button>
                    </div>
                  </motion.div>
                )}
              </div>
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-b-4 border-blue-500"></div>
          </div>
        ) : (
          <>
            {isCurrentDate && predictions.length === 0 && (
              <div className="flex flex-col items-center justify-center h-64">
                <div className="relative">
                  <FontAwesomeIcon icon={faRobot} className="text-6xl text-blue-500 animate-pulse" />
                  <div className="absolute top-0 right-0 animate-ping h-4 w-4 rounded-full bg-blue-400 opacity-75"></div>
                </div>
                <p className="text-2xl font-bold text-blue-600 mt-4 text-center">Las predicciones se están haciendo por la IA</p>
                <p className="text-lg text-gray-600 text-center">En unos momentos podrás ver aquí las predicciones de hoy.</p>
              </div>
            )}
            {!isCurrentDate && predictions.length === 0 && (
              <div className="flex flex-col items-center justify-center h-64">
                <FontAwesomeIcon icon={faCalendarTimes} className="text-6xl text-yellow-500" />
                <p className="text-2xl font-bold text-yellow-600 mt-4 text-center">No hay predicciones para este día.</p>
              </div>
            )}
            {predictions.length > 0 && (
              <motion.div
                className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"
                initial="hidden"
                animate="visible"
                variants={{
                  hidden: { opacity: 0 },
                  visible: {
                    opacity: 1,
                    transition: {
                      staggerChildren: 0.1,
                    },
                  },
                }}
              >
                {filteredPredictions.map((prediction, index) => (
                  <motion.div
                    key={index}
                    className={`bg-white rounded-xl shadow-lg p-6 flex flex-col justify-between transition duration-500 transform hover:scale-105 ${getEstadoBorderClasses(prediction.estado)}`}
                    variants={cardVariants}
                  >
                    <div>
                    <div className="flex justify-between items-center mb-4">
                      <h3 className="text-xl font-semibold text-blue-600">
                        <FontAwesomeIcon icon={faFutbol} className="text-blue-500 text-lg mr-2" />
                        {prediction.event}
                      </h3>
                      {prediction.estado && (
                        <div className="relative group">
                          <div className={`px-2 py-1 rounded-md text-white text-xs font-semibold ${getEstadoClasses(prediction.estado)}`}>
                            {getEstadoText(prediction.estado)}
                          </div>
                          {prediction.estado === 'INDETERMINADO' && (
                            <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 bg-gray-800 text-white text-xs px-2 py-1 rounded shadow-lg opacity-0 group-hover:opacity-100 transition duration-300 pointer-events-none">
                              <div className="relative">
                                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -mt-2 w-0 h-0 border-l-4 border-r-4 border-b-4 border-solid border-transparent border-b-gray-800"></div>
                                <p>
                                  Comprueba manualmente el resultado, ya que el sistema no ha podido hacerlo automáticamente.
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                        <div>
                          <p className="text-gray-600 text-xs mb-1 flex items-center">
                            <FontAwesomeIcon icon={faTrophy} className="text-blue-500 mr-1" />
                            Competición:
                          </p>
                          <p className="text-gray-800 text-sm">{prediction.competition}</p>
                        </div>
                        <div>
                          <p className="text-gray-600 text-xs mb-1 flex items-center">
                            <FontAwesomeIcon icon={faCalendarAlt} className="text-blue-500 mr-1" />
                            Fecha:
                          </p>
                          <p className="text-gray-800 text-sm">
                            {format(parseISO(prediction.date), 'dd/MM/yyyy HH:mm')}
                          </p>
                        </div>
                      </div>
                      <div className="mb-4">
                        <p className="text-gray-600 text-xs mb-2 flex items-center justify-center">
                          <FontAwesomeIcon icon={faChartLine} className="text-blue-500 mr-1" />
                          Predicción:
                        </p>
                        <div className="bg-gradient-to-r from-teal-500 to-emerald-500 rounded-lg p-2">
                          <p className="text-lg font-semibold text-center text-white">
                            {prediction.prediction}
                          </p>
                        </div>
                      </div>
                      <div className="bg-blue-100 rounded-lg shadow-md py-1 px-3 flex items-center justify-center">
                        <p className="text-gray-800 text-sm font-semibold">Cuota: {prediction.odds}</p>
                      </div>
                    </div>
                    <motion.button
                      className="mt-4 bg-gradient-to-r from-blue-500 to-purple-500 text-white font-semibold py-2 px-4 rounded-lg focus:outline-none transition duration-500 hover:shadow-lg hover:shadow-blue-500/50 active:scale-95"
                      onClick={() => handleAnalysisClick(prediction)}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                      Ver análisis de IA
                    </motion.button>
                  </motion.div>
                ))}
              </motion.div>
            )}
          </>
        )}
      </div>
      <AnimatePresence>
        {selectedPrediction && (
          <motion.div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            onClick={handleCloseAnalysis}
          >
            <motion.div
              className="bg-white rounded-2xl shadow-2xl p-8 max-w-2xl w-full mx-4 sm:mx-auto relative"
              initial={{ scale: 0, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0, opacity: 0 }}
              transition={{ duration: 0.3 }}
              onClick={(e) => e.stopPropagation()}
            >
              <div className="absolute top-0 left-0 right-0 h-2 bg-gradient-to-r from-blue-500 to-purple-500 rounded-t-2xl"></div>
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-xl font-bold text-blue-600">
                  <FontAwesomeIcon icon={faRobot} className="mr-2" />
                  Predicción de Bettia v1
                </h3>
                <button
                  className="text-gray-600 hover:text-gray-800 focus:outline-none transition duration-300"
                  onClick={handleCloseAnalysis}
                >
                  <FontAwesomeIcon icon={faTimes} className="h-6 w-6" />
                </button>
              </div>
              <div className="bg-gradient-to-r from-teal-500 to-emerald-500 text-white shadow-md rounded-lg p-4 mb-6">
  <p className="text-sm text-teal-100 mb-2">{selectedPrediction.event}</p>
  <p className="text-lg font-semibold">
    <FontAwesomeIcon icon={faChartLine} className="mr-2" />
    {selectedPrediction.prediction}
  </p>
</div>
<div className="text-gray-700 text-base leading-relaxed">
  {selectedPrediction.analysis.split(/\.\s+/).map((sentence, index, arr) => (
    <React.Fragment key={index}>
      {sentence.trim()}
      {index < arr.length - 1 ? '.' : ''}
      {index < arr.length - 1 && <br />}
      {index < arr.length - 1 && <br />}
    </React.Fragment>
  ))}
</div>
      </motion.div>
    </motion.div>
  )}
</AnimatePresence>
    </motion.div>
  );
}

export default DailyPredictions;