import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot, faPaperPlane, faSpinner } from '@fortawesome/free-solid-svg-icons';
import supabase from '../supabaseClient';

const API_KEY = 'sk-proj-b1bKkCQOIWONmF6iCt9bT3BlbkFJRGPdy4rXGsF4bzwSb5ba';

const ChatbotPage = () => {
  const [messages, setMessages] = useState([
    {
      role: 'system',
      content: 'Eres un asistente de predicciones deportivas. Utiliza la información proporcionada para responder las preguntas del usuario de manera clara y concisa.',
    },
  ]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [dailyPredictions, setDailyPredictions] = useState('');
  const chatContainerRef = useRef(null);

  useEffect(() => {
    const fetchDailyPredictions = async () => {
      try {
        const { data, error } = await supabase.from('daily_predictions').select('*');

        if (error) {
          console.error('Error al obtener las predicciones del día:', error);
        } else {
          const formattedPredictions = data.map((prediction) => {
            return `Evento: ${prediction.event || 'N/A'}
Competición: ${prediction.competition || 'N/A'}
Fecha: ${prediction.date || 'N/A'}
Predicción: ${prediction.prediction || 'N/A'}
Cuota: ${prediction.odds || 'N/A'}
Análisis: ${prediction.analysis || 'N/A'}`;
          }).join('\n\n');

          setDailyPredictions(formattedPredictions);
        }
      } catch (error) {
        console.error('Error al obtener las predicciones del día:', error);
      }
    };

    fetchDailyPredictions();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleChange = (event) => {
    setInputMessage(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSend();
    }
  };

  const handleSendMessage = (messageContent) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { role: 'user', content: messageContent },
    ]);
    setIsLoading(true);
    chatData(messageContent);
  };

  const chatData = async (userMessage) => {
    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${API_KEY}`,
        },
        body: JSON.stringify({
          model: 'gpt-3.5-turbo',
          messages: [
            ...messages,
            { role: 'user', content: `${userMessage}\n\nInformación de predicciones diarias:\n${dailyPredictions}` },
          ],
          temperature: 0.7,
        }),
      });

      if (!response.ok) {
        throw new Error('Oops! Something went wrong while processing your request.');
      }

      const responseData = await response.json();
      console.log('Información enviada a OpenAI:', [
        ...messages,
        { role: 'user', content: `${userMessage}\n\nInformación de predicciones diarias:\n${dailyPredictions}` },
      ]);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          role: 'assistant',
          content: responseData.choices[0].message.content,
        },
      ]);
      setIsLoading(false);
    } catch (error) {
      console.error('Error while fetching chat data:', error);
      setIsLoading(false);
    }
  };

  const handleSend = () => {
    if (inputMessage.trim() !== '') {
      handleSendMessage(inputMessage);
      setInputMessage('');
    }
  };

  const scrollToBottom = () => {
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  };

  return (
    <motion.div
      className="min-h-screen bg-gradient-to-r from-blue-100 to-purple-100 py-8 px-4 sm:px-6 lg:px-8"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="max-w-7xl mx-auto">
        <div className="bg-white rounded-lg shadow-xl overflow-hidden">
          <div className="bg-gradient-to-r from-blue-500 to-purple-500 p-4">
            <h2 className="text-2xl font-bold text-white flex items-center">
              <FontAwesomeIcon icon={faRobot} className="mr-2" />
              Chatbot de predicciones deportivas
            </h2>
          </div>
          <div className="p-6">
            <div className="h-96 overflow-y-auto" ref={chatContainerRef}>
              {messages.slice(1).map((message, index) => (
                <motion.div
                  key={index}
                  className={`mb-4 ${
                    message.role === 'assistant'
                      ? 'bg-blue-100 text-blue-800'
                      : 'bg-gray-100 text-gray-800'
                  } rounded-lg p-4 shadow-md`}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, delay: index * 0.1 }}
                >
                  <p>{message.content}</p>
                </motion.div>
              ))}
              {isLoading && (
                <motion.div
                  className="flex items-center justify-center mt-4"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <FontAwesomeIcon icon={faSpinner} spin className="text-4xl text-blue-500 mr-2" />
                  <p className="text-lg text-blue-500">El asistente está escribiendo...</p>
                </motion.div>
              )}
            </div>
            <div className="flex mt-6">
              <input
                type="text"
                className="flex-grow px-4 py-2 border border-gray-300 rounded-l-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                placeholder="Escribe tu mensaje..."
                value={inputMessage}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                disabled={isLoading}
              />
              <button
                className="px-6 py-2 bg-blue-500 text-white rounded-r-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-300"
                onClick={handleSend}
                disabled={isLoading}
              >
                <FontAwesomeIcon icon={faPaperPlane} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ChatbotPage;