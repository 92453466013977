import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCalendarAlt, faUser, faSignOutAlt, faBars, faTimes, faFillDrip, faTrash, faRobot, faCoins } from '@fortawesome/free-solid-svg-icons';
import supabase from '../supabaseClient';

function Sidebar(props) {
  const [isOpen, setIsOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
    }
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleFillPredictions = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const { data: userPrediction, error } = await supabase
        .from('user_predictions')
        .select('*')
        .eq('user_id', user.id)
        .single();
  
      if (error && error.code !== 'PGRST116') {
        console.error('Error al obtener las predicciones del usuario:', error);
        return;
      }
  
      if (userPrediction) {
        await supabase
          .from('user_predictions')
          .update({ remaining_predictions: 10 })
          .eq('id', userPrediction.id);
      } else {
        await supabase.from('user_predictions').insert({
          user_id: user.id,
          remaining_predictions: 10,
        });
      }
    } catch (error) {
      console.error('Error al rellenar las predicciones:', error);
    }
  };
  
  const handleClearPredictions = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const { data: userPrediction, error } = await supabase
        .from('user_predictions')
        .select('*')
        .eq('user_id', user.id)
        .single();
  
      if (error && error.code !== 'PGRST116') {
        console.error('Error al obtener las predicciones del usuario:', error);
        return;
      }
  
      if (userPrediction) {
        await supabase
          .from('user_predictions')
          .update({ remaining_predictions: 0 })
          .eq('id', userPrediction.id);
      } else {
        await supabase.from('user_predictions').insert({
          user_id: user.id,
          remaining_predictions: 0,
        });
      }
    } catch (error) {
      console.error('Error al dejar a 0 las predicciones:', error);
    }
  };

  return (
    <>
      <div
        className={`fixed left-0 top-0 bottom-0 w-64 bg-gradient-to-b from-blue-600 to-indigo-800 text-white shadow-lg flex flex-col transition-all duration-300 ease-in-out transform ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } md:translate-x-0 z-50`}
      >
        <div className="flex items-center justify-between h-16 px-4">
          <Link to="/" className="text-white text-2xl font-bold flex items-center">
            <span className="mr-2">BetWise</span>
            <span className="bg-yellow-500 text-black text-xs font-semibold px-2 py-1 rounded-full uppercase">Beta</span>
          </Link>
          <button className="text-white focus:outline-none md:hidden" onClick={toggleSidebar}>
            <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
          </button>
        </div>
        <nav className="flex-1 overflow-y-auto">
          <ul className="py-4">
            <li>
              <Link
                to="/prediction"
                className="flex items-center px-6 py-3 text-white hover:bg-indigo-900 transition duration-300"
              >
                <FontAwesomeIcon icon={faSearch} className="mr-4" />
                <span className="font-semibold">Buscar eventos</span>
              </Link>
            </li>
            <li>
              <Link
                to="/daily-predictions"
                className="flex items-center px-6 py-3 text-white hover:bg-indigo-900 transition duration-300"
              >
                <FontAwesomeIcon icon={faCalendarAlt} className="mr-4" />
                <span className="font-semibold">Predicciones diarias</span>
              </Link>
            </li>
            <li>
              <Link
                to="/profile"
                className="flex items-center px-6 py-3 text-white hover:bg-indigo-900 transition duration-300"
              >
                <FontAwesomeIcon icon={faUser} className="mr-4" />
                <span className="font-semibold">Perfil</span>
              </Link>
            </li>
            <li>
              <Link
                to="/chatbot"
                className="flex items-center px-6 py-3 text-white hover:bg-indigo-900 transition duration-300"
              >
                <FontAwesomeIcon icon={faRobot} className="mr-4" />
                <span className="font-semibold">Chatbot con IA</span>
              </Link>
            </li>
            <li>
              <Link
                to="/betting-distribution"
                className="flex items-center px-6 py-3 text-white hover:bg-indigo-900 transition duration-300"
              >
                <FontAwesomeIcon icon={faCoins} className="mr-4" />
                <span className="font-semibold">Distribución de apuestas</span>
              </Link>
            </li>
          </ul>
        </nav>
        <div className="p-4 flex flex-col space-y-2">
          <button
            onClick={handleFillPredictions}
            className="flex items-center justify-center px-4 py-2 text-white bg-green-500 rounded-lg shadow-md hover:bg-green-600 transition duration-300"
          >
            <FontAwesomeIcon icon={faFillDrip} className="mr-2" />
            <span className="font-semibold text-sm">Rellenar predicciones</span>
          </button>
          <div className="text-center text-xs text-gray-300">
            Restablece tus predicciones diarias a 10.
          </div>
          <button
            onClick={handleClearPredictions}
            className="flex items-center justify-center px-4 py-2 text-white bg-red-500 rounded-lg shadow-md hover:bg-red-600 transition duration-300"
          >
            <FontAwesomeIcon icon={faTrash} className="mr-2" />
            <span className="font-semibold text-sm">Dejar a 0</span>
          </button>
          <div className="text-center text-xs text-gray-300">
            Establece tus predicciones diarias a 0.
          </div>
        </div>
        <button
          onClick={handleLogout}
          className="flex items-center px-6 py-3 text-white hover:bg-indigo-900 transition duration-300"
        >
          <FontAwesomeIcon icon={faSignOutAlt} className="mr-4" />
          <span className="font-semibold">Cerrar sesión</span>
        </button>
      </div>
      <div
        className={`fixed inset-0 bg-black opacity-50 transition-opacity duration-300 ease-in-out ${
          isOpen ? 'visible' : 'invisible'
        } md:hidden`}
        onClick={toggleSidebar}
      ></div>
      <div className={`ml-0 md:ml-64 transition-all duration-300 ease-in-out ${isOpen ? 'ml-64' : 'ml-0'}`}>
        {props.children}
      </div>
    </>
  );
}

export default Sidebar;